import queryString from 'query-string'
import { storeToRefs } from 'pinia'
import { useLobbyGameApi } from '@/api/lobby-game'
import { useGameStore } from '~~/store/game'
import { useLoading } from '~/composables/useLoading'
import { PAGE } from '~/constants/router'
import { IItemCongGame } from '~/types/lobby.type'
import { IGameItem } from '~/types/common'
export const useRecommentGame = () => {
  const { $device, $pinia, $axios } = useNuxtApp()
  const { LIST_LOBBY_GAME } = useLobbyGameApi()
  const { loading, load, isLoading } = useLoading()
  const listRecommentGame = ref<IGameItem[]>([])

  const fetchRecommentGame = async () => {
    loading()
    let queryUrl = ''
    queryUrl = queryString.stringify({
      path_alias: 'all',
      limit: 10,
      page: 1,
      sort_by: 'TOP_USER_BET',
      provider_code: ''
    })
    try {
      const { data: response } = await $axios.get(`${LIST_LOBBY_GAME}?${queryUrl}`)
      if (response.status === 'OK') {
        listRecommentGame.value = response.data?.items
        load()
      }
    } catch (error: any) {
      load()
      console.log('error', error?.response?.data?.message)
    }
  }

  return {
    isLoading,
    listRecommentGame,
    fetchRecommentGame
  }
}
