<template>
  <div v-if="listRecommentGame.length > 0" class="recommended-game">
    <div class="width-container container-custom">
      <SectionTitle :title="title" />
      <div
        class="recommended-game__pagination"
        :class="{ hide: !listRecommentGame || listRecommentGame.length === 0 || !!props.isHideNavigation }"
      >
        <div
          :class="`recommended-game__navigation ${
            props.isRoundedBtnNavigation && 'rounded-btn-navigation'
          } js-game-prev-1`"
        >
          <span class="icon-arrow-right" />
        </div>
        <div
          :class="`recommended-game__navigation ${
            props.isRoundedBtnNavigation && 'rounded-btn-navigation'
          } js-game-next-1`"
        >
          <span class="icon-arrow-right" />
        </div>
      </div>

      <div :class="`recommended-game__content ${props.class}`">
        <Swiper ref="swiperRecomment" v-bind="swiperOption" class="swiper">
          <SwiperSlide v-for="(game, index) in listRecommentGame" :key="index" class="swiper-slide">
            <CommonGamesItemGame class="item-game" :item="game" :class="`${game?.class ? game?.class : ''}`" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Navigation } from 'swiper'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import { useRecommentGame } from '~~/composables/game/useRecommentGame'
const SectionTitle = defineAsyncComponent(() => import('@/components/common/title-section.vue'))
const { isLoading, fetchRecommentGame, listRecommentGame } = useRecommentGame()
const swiperRecomment = ref<any>(null)

const props = defineProps({
  title: {
    type: String,
    default: 'Game Đề Xuất'
  },
  isAutoPlay: {
    type: Boolean,
    default: true
  },
  isRoundedBtnNavigation: {
    type: Boolean,
    default: false
  },
  slidesPerView: {
    type: Number,
    default: 7
  },
  spaceBetween: {
    type: Number,
    default: 16
  },
  isHideNavigation: {
    type: Boolean,
    default: false
  },
  class: {
    type: String,
    default: ''
  },
  isLoop: {
    type: Boolean,
    default: false
  }
})

const swiperOption = reactive({
  slidesPerView: props.slidesPerView,
  slidesPerGroup: 1,
  spaceBetween: props.spaceBetween,
  navigation: {
    nextEl: '.js-game-next-1',
    prevEl: '.js-game-prev-1'
  },
  loop: true,
  autoplay: props.isAutoPlay && {
    delay: 3000,
    disableOnInteraction: false
  },
  modules: [Autoplay, Navigation]
})

useAsyncData(async () => {
  await fetchRecommentGame()
})
</script>
<style scoped lang="scss" src="~/assets/scss/components/common/recommended-game.scss"></style>
